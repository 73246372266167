import requests from "./request";

//登录发送验证码接口
export const sendCode = (data) => {
    return requests({ url: "/api/sms/sendCode", method: "post", data });
};

//密码登录
export const loginByPhone = (data) => {
    return requests({ url: "/api/user/loginByPhone", method: "post", data });
};

//退出登录
export const logout = (data) => {
    return requests({ url: "/api/user/logout", method: "post", data });
};


// 模特列表
export const getVideoList = (data) => {
    return requests({ url: "/api/video/getVideoList", method: "post", data });
};

// 背景列表
export const getbglist = (data) => {
    return requests({ url: "/api/background/getbglist", method: "post", data });
};

// 音频列表
export const audioList = (data) => {
    return requests({ url: "/api/audio/audioList", method: "post", data });
};

// 我的音频
export const userAudioList = (data) => {
    return requests({ url: "/api/audio/userAudioList", method: "post", data });
};

// 修改音频
export const audioUpdate = (data) => {
    return requests({ url: "/api/audio/audioUpdate", method: "post", data });
};

// 生成视频
export const submitTask = (data) => {
    return requests({ url: "/api/task/submitTask", method: "post", data });
};

// 获取我的视频历史记录
export const history = (data) => {
    return requests({ url: "/api/task/history", method: "post", data });
};

// 修改我的视频
export const updateresult = (data) => {
    return requests({ url: "/api/task/updateresult", method: "post", data });
};

// 轮循
export const videoStatus = (data) => {
    return requests({ url: "/api/task/status", method: "post", data });
};

// 模板
export const getVideoTemplete = (data) => {
    return requests({ url: "/api/video/getVideoTemplete", method: "post", data });
};