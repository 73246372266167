<template>
    <div class="layout-box">
        <div class="layout">
            <div class="logo"><img src="@/assets/logo.png" alt=""></div>

            <el-tabs tab-position="left" type="border-card" class="tabLeft" @tab-click="add" v-model="activeName">
                <!--  style="height: 100vh;" -->
                <!-- 模板 -->
                <el-tab-pane name="template">
                    <span slot="label"><i class="el-icon-menu"></i>模板</span>
                    <div class="choose">
                        <h2>模板选择</h2>
                        <div class="model">
                            <!-- <div class="tab">
                            <div @click="tabClick" ref="tab">
                                <span :class="'active'">全部</span>
                                <span>知识口播</span>
                                <span>情感口播</span>
                                <span>节目热点</span>
                            </div>
                            <i class="el-icon-arrow-down"></i>
                        </div> -->
                            <switchTab :tabList="allTabList[0]"></switchTab>
                            <ul class="imgContainer" style="overflow-y:auto; ">
                                <li v-for="item in template_list" :key="item.templeteid" @click="templateSelect(item)">
                                    <img :src="item.preview" alt="">
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 人像 -->
                <el-tab-pane name="portrait">
                    <span slot="label"><i class="el-icon-user-solid"></i>人像</span>
                    <div class="choose">
                        <h2>选择数字人像</h2>
                        <!-- <h3>我的</h3> -->
                        <div class="tailored">
                            <!-- <div class="top">
                            <span class="active">定制数字人</span>
                            <span>照片数字人</span>
                        </div>
                        <div class="button">
                            <i class="el-icon-picture-outline"></i>
                            <div class="txt">
                                <div>定制你的专属数字人</div>
                                <div>1:1复刻形象声音</div>
                            </div>
                            <div class="btn">立即资讯</div>
                        </div>
                        <div class="upload">
                            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false">
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-upload avatar-uploader-icon"> 上传</i>
                            </el-upload>
                        </div> -->
                            <switchTab :tabList="allTabList[1]"></switchTab>
                            <ul class="imgContainer" style="overflow-y:auto;">
                                <li v-for="item in people_list" :key="item.videoid" @click="peopleSelected(item)">
                                    <img style="background: #000;" :src="item.thumbnail" alt="">
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 音频 -->
                <!-- <el-tab-pane>
                <span slot="label"><i class="el-icon-service"></i>音频</span>
                <div class="choose">
                    <h2>选择音频</h2>
                    <div class="voice">
                        <div class="top" @click="musClick" ref="mus">
                            <span class="active">音乐</span>
                            <span>音效</span>
                            <span>自定义</span>
                        </div>

                        <switchTab :tabList="allTabList[2]" v-if="musOptions.musType === '音乐'"></switchTab>
                        <div class="voiceContainer" style="overflow-y:auto;" v-if="musOptions.isDIY === false">
                            <div class="voiceItem active">
                                <div class="img2"></div>
                                <div>
                                    <div>慢节奏纯音乐</div>
                                    <div>02:22</div>
                                </div>
                            </div>
                            <div class="voiceItem" v-for="(item, index) in 100" :key="index">
                                <div class="img"></div>
                                <div>
                                    <div>慢节奏纯音乐</div>
                                    <div>02:22</div>
                                </div>
                            </div>

                        </div>
                        <div class="uploadContainer" v-else>
                            <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/"
                                multiple>
                                <i class="el-icon-upload"></i>
                            </el-upload>
                            <div class="el-upload__text">
                                <div>点击上传你的素材</div>
                                <div class="el-upload__tip" slot="tip">支持JPG/PNG/GIF，MP3/WAV/MP4/MOV 总大小200MB</div>
                            </div>
                        </div>


                    </div>
                </div>
            </el-tab-pane> -->
                <!-- 背景 -->
                <el-tab-pane name="bg">
                    <span slot="label"><i class="el-icon-picture"></i>背景</span>
                    <div class="choose">
                        <h2>选择背景图</h2>
                        <div class="background">
                            <!-- <div class="type">
                            <span class="active">纯色</span>
                            <span>变色</span>
                        </div>
                        <div class="picker">
                            <div class="pickerItem">
                            </div>
                            <div class="separate"></div>
                            <div class="pickerItem">
                            </div>
                            <div class="pickerItem">
                            </div>
                            <div class="pickerItem">
                            </div>
                            <div class="pickerItem">
                            </div>
                            <div class="pickerItem">
                            </div>
                        </div> -->
                            <switchTab :tabList="allTabList[3]"></switchTab>
                            <ul class="imgContainer" style="overflow-y:auto;">
                                <li v-for="item in bg_list" :key="item.backgroundid" @click="bgSelected(item)">
                                    <img :src="item.thumbnail" alt="">
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 素材 -->
                <el-tab-pane name="material">
                    <span slot="label"><i class="el-icon-folder-opened"></i>素材</span>
                    <div class="choose">
                        <h2>我的素材</h2>
                        <div class="material">
                            <switchTab :tabList="allTabList[4]"></switchTab>
                            <div class="uploadContainer">
                                <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/"
                                    multiple>
                                    <i class="el-icon-upload"></i>
                                </el-upload>
                                <div class="el-upload__text">
                                    <div>点击上传你的素材</div>
                                    <div class="el-upload__tip" slot="tip">支持JPG/PNG/GIF，MP3/WAV/MP4/MOV 总大小200MB</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 我的视频 -->
                <el-tab-pane ref="shipin" name="myVideo">
                    <span slot="label"><i class="el-icon-folder-opened"></i>视频</span>
                </el-tab-pane>
                <!-- 定制 -->
                <el-tab-pane ref="dinzhi" name="custom">
                    <span slot="label"><i class="el-icon-folder-opened"></i>定制</span>
                </el-tab-pane>
            </el-tabs>

            <div class="user-icon">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <img src="@/assets/user_icon.png" alt="">
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- <span class="login" @click="is_login = true">登录</span> -->

            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>

        </div>
        <myLogin v-if="is_login" @close="closeLogin" @loginFn="loginFn"></myLogin>

    </div>
</template>

<script>

import switchTab from '@/components/switchTab.vue'
import myLogin from "@/components/my-login.vue"


import { getbglist, getVideoList, getVideoTemplete, logout } from '@/request/api.js';

export default {
    name: "layoutView",
    data() {
        return {
            count: 100,
            is_login: false,
            activeName: "template",
            color1: '',
            imageUrl: '',
            template_list: [],
            bg_list: [],
            people_list: [],
            allTabList: [
                ['知识口播', '情感口播', '节目热点'],
                ['女性', '男性', '中性', '老年'],
                ['热门', '情感', '古风', '欢快'],
                ['中式', '户外', '办公', '室内',],
                ['图片', '视频', '音效',]
            ],
            musOptions: {
                musType: '音乐',
                isDIY: false
            },
            // localStorage: localStorage

        }
    },
    components: {
        myLogin,
        switchTab
    },
    created() {
        let that = this;


        getVideoTemplete({}).then((res) => {
            console.log("获取模版列表", res);
            if (res.data) {
                that.template_list = res.data;

                that.$bus.$emit('bg_data', {
                    data: {
                        backgroundname: res.data[0].backgroundname,
                        preview: res.data[0].bgpreview,
                        backgroundtype: 0,
                        backgroundid: res.data[0].backgroundid

                    },
                    type: 0,
                })

                that.$bus.$emit('people_data', {
                    data: {
                        videoname: res.data[0].videoname,
                        preview: res.data[0].videopreview,
                        frameurl: res.data[0].frameurl,
                        framecount: res.data[0].framecount,
                        videoid: res.data[0].videoid

                    },
                    type: 0,
                })
            }

        }).catch((res) => {
            console.log("获取模版失败", res);

        });

        getbglist({}).then((res) => {
            console.log("获取背景列表", res,);
            if (res.data) {
                that.bg_list = res.data;
                // that.$bus.$emit('bg_data',{
                //     data:res.data[0],
                //     type:0,
                // })
            }

        }).catch((res) => {
            console.log("获取背景错误", res);

        });

        getVideoList({}).then((res) => {
            console.log("获取人像列表", res);
            if (res.data) {
                that.people_list = res.data;
                // that.$bus.$emit('people_data',{
                //     data:res.data[0],
                //     type:0,
                // })
            }

        }).catch((res) => {
            console.log("获取背景错误", res);

        });



    },
    methods: {
        closeLogin() {
            this.is_login = false;
        },
        loginFn() {
            this.is_login = false;
        },
        exit() {
            let that = this;
            this.$confirm('确认退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                logout({}).then(exitFn).catch(exitFn);

            }).catch(() => {});
            function exitFn() {
                localStorage.clear();
                sessionStorage.clear();
                let backlen = window.history.length - 1;
                window.history.go(-backlen);
                that.$router.replace({ path: '/login' })
            }
        },
        activeFn(activeName) {
            this.activeName = activeName
        },
        templateSelect(e) {

            // backgroundname: "background1.png"
            // bgpreview: "https://file.gdcar.net/ai-digital/background/background1.png"
            // preview: "https://file.gdcar.net/ai-digital/video/thumbnail/merge_1_10.png"
            // templeteid: "merge_1"
            // templetename: "merge_1"
            // videoname: "qgzb_15s.mp4"
            // videopreview: "https://file.gdca"
            console.log("选择的模版", e);

            this.$bus.$emit('bg_data', {
                data: {
                    backgroundname: e.backgroundname,
                    preview: e.bgpreview,
                    backgroundtype: 0,
                    backgroundid: e.backgroundid
                },
                type: 1,
            })

            this.$bus.$emit('people_data', {
                data: {
                    videoname: e.videoname,
                    preview: e.videopreview,
                    frameurl: e.frameurl,
                    framecount: e.framecount,
                    videoid: e.videoid
                },
                type: 1,
            })
        },
        bgSelected(e) {
            console.log(e);
            this.$bus.$emit('bg_data', {
                data: e,
                type: 1,
            })
        },
        peopleSelected(e) {
            this.$bus.$emit('people_data', {
                data: e,
                type: 1,
            })
        },
        beforeAvatarUpload() { },
        handleAvatarSuccess() { },
        add(e) {
            this.$router.push({ path: '/' + e.name })
        },
        musClick(e) {
            if (e.target.tagName === 'SPAN') {
                if (e.target.innerText === '音乐') {
                    this.musOptions.musType = '音乐'
                } else if (e.target.innerText === '音效') {
                    this.musOptions.musType = '音效'
                    this.musOptions.isDIY = false
                } else {
                    this.musOptions.musType = '自定义'
                    this.musOptions.isDIY = true
                }
                [...this.$refs.mus.children].forEach(element => {
                    element.classList.remove('active')
                });
                e.target.classList.toggle('active');
            }
        },
    },
    mounted() {
        this.activeName = this.$route.name;
    }
}
</script>

<style lang="less" scoped>
.layout-box {
    height: 100%;
}

.layout {

    // height: 100vh;
    height: 100%;

    .login {
        width: 54px;
        height: 54px;
        line-height: 54px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        background: linear-gradient(132deg, #1FB7F7 0%, #1DDCFF 92%);
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: 34px;
        margin-left: 28px;
        cursor: pointer;
    }

    .user-icon {
        width: 54px;
        height: 54px;
        line-height: 54px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        background: linear-gradient(132deg, #1FB7F7 0%, #1DDCFF 92%);
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: 34px;
        margin-left: 28px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .logo {
        position: absolute;
        top: 24px;
        left: 24px;
        width: 60px;
        height: 60px;
        z-index: 10;

        img {
            width: 100%;
            height: 100%;
        }
    }

    display: flex;
    // height: 100vh;

    /deep/.el-tabs__header {
        margin: 0;
    }

    /deep/.el-tabs--border-card {
        border: none;
    }

    // 标签栏左侧素材区域样式
    /deep/.el-tabs__content {
        max-width: 378px;
        padding: 0;
        background-color: #273341;
        color: white;
        // height: 100vh;
        height: 100%;
        min-width: 0;

        .el-tab-pane {
            height: 100%;
        }
    }

    // 左侧标签栏样式
    /deep/.el-tabs__nav-scroll {
        background-color: #0D1116;
        padding-top: 115px;

        .el-tabs__item {
            width: 109px;
            height: 76px;
            padding: 20px;
            font-size: 18px;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                transform: translateY(-10px);

                i {
                    font-size: 22px;
                    transform: translateY(5px);
                }
            }

        }

        .is-active {
            background-color: #273341;
            color: #1DDCFF;
        }
    }

    /deep/ .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
        border: none;
        border-color: transparent;
    }

    // 饿了吗上传组件样式
    /deep/ .el-upload-dragger {
        width: 90px;
        height: 78px;
        background-color: #273341;

        .el-icon-upload {
            font-size: 45px;
            line-height: 78px;
            margin: 0;
        }
    }

    //  滚动条
    ::-webkit-scrollbar {
        width: 6px;
        height: 16px;
        background-color: #273341;
    }

    // 滚动条滑块
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #757679;
        height: 50%;
    }

    .choose {
        padding: 0 20px;
        height: 100%;



        .model,
        .tailored,
        .background,
        .material {
            display: flex;
            flex-direction: column;
            width: 350px;
            height: 100%;

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                // height: 85vh;
                // width: 350px;
                overflow: hidden;
                padding-bottom: 30px;
                padding-bottom: 100px;

                li {
                    list-style-type: none;
                    width: 106px;
                    // height: 168px;
                    // background-color: aquamarine;
                    margin-right: 10px;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-bottom: 10px;

                    img,
                    video {
                        width: 100%;
                        height: 100%
                    }

                    &:nth-child(3n) {
                        margin-right: 0;
                    }

                }
            }

        }

        .voice,
        .material {
            width: 378px;
        }

        .voiceContainer {
            margin-top: 25px;
            height: 80vh;
            width: 342px;
            margin-left: 5px;
            padding-bottom: 30px;

            .voiceItem {
                height: 82px;
                width: 322px;
                background-color: #1B2128;
                border-radius: 4px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                padding-left: 21px;
                margin-right: 5px;

                .img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: #273341;
                    margin-right: 16px;
                }

                .img2 {
                    background: linear-gradient(180deg, #1FB2EE 0%, #1DCDF7 100%);
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 16px;

                }


            }

            .active {
                border: 1px solid #1FB7EF;

            }
        }

        .tailored {
            .upload {
                background-color: #1B2128;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                width: 326px;

                .avatar-uploader .el-upload {
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                }

                .avatar-uploader .el-upload:hover {
                    border-color: #409EFF;
                }

                .avatar-uploader-icon {
                    font-size: 16px;
                    color: #8c939d;
                    width: 178px;
                    height: 46px;
                    line-height: 46px;
                    text-align: center;
                }

                .avatar {
                    width: 178px;
                    height: 178px;
                    display: block;
                }
            }
        }

        .background {
            margin-top: 24px;

            .type {
                font-size: 18px;
                margin-bottom: 10px;

                span {
                    margin-right: 46px;
                }
            }

            .picker {
                display: flex;
                margin-bottom: 26px;

                .separate {
                    width: 1px;
                    height: 36px;
                    background-color: #fff;
                    margin-right: 23px;
                }

                .pickerItem {
                    width: 36px;
                    height: 36px;
                    background-color: #fff;
                    border-radius: 6px;
                    margin-right: 16px;

                    &:nth-child(1) {
                        margin-right: 22px;
                        border-right: 1 solid red;
                    }

                    &:nth-child(2) {
                        background: linear-gradient(180deg, #FF7068 0%, #FF8562 100%);
                    }

                    &:nth-child(3) {
                        background: linear-gradient(180deg, #25D5BA 0%, #4A6AF5 97%);
                    }

                    &:nth-child(4) {
                        background: linear-gradient(180deg, #9143E5 0%, #F898C4 99%);
                    }

                    &:nth-child(5) {
                        background: linear-gradient(180deg, #FFF194 0%, #FFB367 100%);
                    }

                    &:nth-child(6) {
                        background: linear-gradient(180deg, #697597 0%, #3E4F65 100%, #3D4E63 100%);
                    }



                }
            }

        }

        .material,
        .voice {
            .uploadContainer {

                text-align: center;
                margin-top: 225px;
                width: 358px;

                .el-upload__text {
                    margin-top: 13px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;


                    .el-upload__tip {
                        width: 259px;
                    }
                }
            }
        }

        .top {
            display: flex;
            background-color: #1B2128;
            justify-content: space-between;
            margin-bottom: 15px;
            padding: 6px;
            color: #999999;
            border-radius: 4px;
            width: 326px;
            cursor: pointer;

            span {
                flex: 1;
                text-align: center;
                height: 34px;
                line-height: 34px;
                font-size: 18px;
            }

            .active {
                background: linear-gradient(138deg, #1FADEC 0%, #1DD2F9 100%, #1EBCF7 100%);
                border-radius: 4px;
                color: white;
            }
        }

        // .button {
        //     display: flex;
        //     align-items: center;
        //     padding-left: 20px;
        //     background-color: #1B2128;
        //     height: 84px;
        //     border-radius: 4px;
        //     margin-bottom: 30px;

        //     i {
        //         margin-right: 13px;
        //     }

        //     .txt {
        //         margin-right: 30px;

        //         div {
        //             &:nth-child(1) {
        //                 font-size: 18px;
        //                 color: white;
        //             }

        //             &:nth-child(2) {
        //                 font-size: 14px;
        //                 color: #999999;
        //             }
        //         }

        //     }

        //     .btn {
        //         font-size: 14px;
        //         color: #1FADEC;
        //     }

        // }

        h2 {
            font-size: 20px;
            margin-top: 29px;
            margin-bottom: 12px;
        }

        h3 {
            margin-top: 25px;
            font-size: 20px;
            margin-bottom: 13px;
        }
    }

}
</style>
<style></style>
