import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/base.css'
import AudioVisual from 'vue-audio-visual'

// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import VueFullPage from 'vue-fullpage.js'
// Vue.use(VueFullPage);
// vue2  使用0.1.5

Vue.use(AudioVisual)
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$bus = new Vue()

new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
