<template>
    <div class="tab">
        <div @click="tabClick" ref="tab">
            <span :class="'active'" v-if="isAll">全部</span>
            <span v-for="(item, index) in tabList" :key="index">{{ item }}</span>
        </div>
        <i v-if="isEdit" class="el-icon-arrow-down"></i>
    </div>
</template>

<script>
export default {
    name: 'switchTab',
    props: {
        tabList: {
            type: Array,
            required: true
        },
        isEdit: {
            type: Boolean,
            default:true
        },
        isAll :{
            type: Boolean,
            default:true
        }
    },
    data() {
        return {}
    },
    methods: {
        tabClick(e) {
            if (e.target.tagName === 'SPAN') {
                [...this.$refs.tab.children].forEach(element => {
                    element.classList.remove('active')
                });
                e.target.classList.toggle('active');
            }

            this.$emit('tabClick', e.target.innerText)
        },
    },
    mounted() {
        // console.log(this.$refs.tab.children,'this.$refs.tab.children');
        
        this.$refs.tab.children[0].classList.add('active')
    }

}
</script>

<style lang="less" scoped>
.tab {
    font-size: 16px;
    color: #999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    span {
        margin-right: 22px;
        position: relative;
        // &:nth-child(1) {

        // }
    }

    .active {
        color: #fff;

        &::after {
            content: '';
            width: 17px;
            height: 2px;
            display: inline-block;
            background-color: red;
            position: absolute;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(135deg, #1FB9F7 0%, #1DDDFF 100%);

        }
    }
}
</style>